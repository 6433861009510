import React from 'react';
import DOMPurify from 'dompurify';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import Alert from 'components/ui-components-v2/Alert';
import './styles/main.scss';

/**
 * InlineAlert
 * This shows a one line alert, like an info box, warning or error
 */
export default class InlineAlert extends React.Component {
    render() {
        const { type = 'error', message, loading = false, inputKey } = this.props;
        if (message) {
            return (
                <div className={'inline-alert inline-alert--' + type} data-input-key={inputKey}>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
                    {loading && (
                        <div className="inline-alert__loader">
                            <LinearProgress />
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <Alert
                    icon={false}
                    severity={type === 'alert' ? 'warning' : type}
                    className="inline-alert---mui-alert"
                    variant="outlined"
                    data-input-key={inputKey}>
                    {this.props.children}
                </Alert>
            );
        }
    }
}
