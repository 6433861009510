import React from 'react';
import { TemplateType } from 'types/templates.type';
import classNames from 'classnames';
import TemplateIcon from './data/templateIcon';
import './styles/main.scss';

interface Props {
    templateType: TemplateType;
    size: 'large' | 'medium' | 'small';
    noSquarcle?: boolean;
}

const TemplatePlaceholderIcon = ({ templateType = 'displayAd', size = 'large', noSquarcle = true }: Props): JSX.Element => {
    return (
        <div className="template-placeholder-icon">
            <div
                className={classNames('template-placeholder-icon__image', {
                    'template-placeholder-icon__image--small': size === 'small',
                    'template-placeholder-icon__image--medium': size === 'medium',
                    'template-placeholder-icon__image--large': size === 'large',
                    'template-placeholder-icon__image--purple': ['dynamicAfterEffects', 'dynamicInDesign'].includes(templateType)
                })}>
                <TemplateIcon templateType={templateType} size="medium" noSquarcle={noSquarcle} />
            </div>
        </div>
    );
};

export default TemplatePlaceholderIcon;
