import React from 'react';
import { ReactSVG } from 'react-svg';
import { GenericSvgIcon } from '../types/generic-svg-icon.type';

interface Props {
    icon: GenericSvgIcon;
    className?: string;
    svgClassName?: string;
}

const GenericIcon = ({ icon, className, svgClassName }: Props) => {
    return (
        <ReactSVG
            src={`/icon/${icon}.svg`}
            beforeInjection={(svg) => {
                if (svgClassName) svg.classList.add(svgClassName);
            }}
            className={className}
        />
    );
};

export default GenericIcon;
