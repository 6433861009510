import React from 'react';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBack';
import classNames from 'classnames';
import Dialog from 'components/ui-components-v2/Dialog';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import Tooltip from 'components/ui-components-v2/Tooltip';

import '../styles/main.scss';

const DialogTitle = (props) => {
    const { children, onClose, canGoBack, onGoBack } = props;
    return (
        <MuiDialogTitle
            variant="h4"
            component="div"
            className={classNames('ui-components-dialog__title', {
                ['ui-components-dialog__title--has-close']: onClose
            })}>
            {canGoBack && onGoBack && (
                <IconButton aria-label="close" className="ui-components-dialog__title__back-button" onClick={onGoBack} size="small">
                    <ArrowBack fontSize="small" />
                </IconButton>
            )}
            {children}
            {!!onClose && (
                <IconButton aria-label="close" className="ui-components-dialog__title__close-button" onClick={onClose} size="small">
                    <CloseIcon fontSize="small" />
                </IconButton>
            )}
        </MuiDialogTitle>
    );
};

class CustomizedDialogs extends React.Component {
    static propTypes = {
        maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
        fixedHeight: PropTypes.bool,
        fixedHeightBig: PropTypes.bool,
        fixedHeightPaperScrollPaper: PropTypes.bool,
        fullScreen: PropTypes.bool,
        onClose: PropTypes.func,
        open: PropTypes.bool,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        onConfirm: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        confirmText: PropTypes.string,
        confirmIcon: PropTypes.any,
        confirmButtonDisabled: PropTypes.bool,
        subText: PropTypes.string,
        showCancel: PropTypes.bool,
        classes: PropTypes.object,
        onGoBack: PropTypes.func,
        canGoBack: PropTypes.bool,
        tooltip: PropTypes.string
    };

    static defaultProps = {
        maxWidth: 'lg',
        confirmText: 'Save changes',
        onClose: () => {},
        open: false,
        onConfirm: false,
        classes: {},
        showCancel: false,
        canGoBack: false
    };

    render() {
        const {
            maxWidth,
            fixedHeight,
            fixedHeightBig,
            fixedHeightPaperScrollPaper,
            fullScreen,
            onClose,
            open,
            title,
            children,
            onConfirm,
            confirmText,
            confirmIcon,
            subText,
            showCancel,
            hideOverflowY,
            confirmButtonDisabled,
            canGoBack,
            onGoBack,
            classes,
            contentPadding,
            tooltip,
            ...other
        } = this.props;
        return (
            <Dialog
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                onClose={onClose}
                open={open}
                classes={{
                    paper: classNames({
                        ['ui-components-dialog--fixed-height']: fixedHeight,
                        ['ui-components-dialog--fixed-height-big']: fixedHeightBig
                    }),
                    paperScrollPaper: classNames({
                        ['ui-components-dialog--fixed-height-paper-scroll-paper']: fixedHeightPaperScrollPaper
                    })
                }}
                {...other}>
                {title && (
                    <DialogTitle canGoBack={canGoBack} onGoBack={onGoBack} onClose={onClose} className={classes.title}>
                        {title}
                    </DialogTitle>
                )}
                <DialogContent
                    dividers
                    className={classNames('ui-components-dialog__content', classes.content, {
                        'ui-components-dialog__content--hide-overflow-y': hideOverflowY,
                        'ui-components-dialog__content--content-padding': contentPadding
                    })}>
                    {children}
                </DialogContent>
                {onConfirm && (
                    <DialogActions classes={{ root: classNames('ui-components-dialog__actions', classes.actions) }}>
                        <div className="ui-components-dialog__actions__left">
                            {showCancel && (
                                <Button onClick={onClose} variant="text" color="primary">
                                    {Translation.get('actions.cancel', 'common')}
                                </Button>
                            )}
                        </div>
                        {subText && <div className="ui-components-dialog__actions__subtext">{subText}</div>}
                        <Tooltip title={tooltip} disableHoverListener={!tooltip}>
                            <div>
                                <Button
                                    onClick={onConfirm}
                                    color="primary"
                                    variant="contained"
                                    endIcon={confirmIcon ? confirmIcon : null}
                                    disabled={confirmButtonDisabled}>
                                    {confirmText}
                                </Button>
                            </div>
                        </Tooltip>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}

export default CustomizedDialogs;
