import { useState, useMemo, useEffect } from 'react';
import { convertHexToHexAlpha, convertHexToRgb, convertRgbToHex, isHexColor } from 'helpers/colors';
import cloneDeep from 'helpers/cloneDeep';

const useColorPicker = (color, onChange, useGradients) => {
    const [showColorPickerModal, setShowColorPickerModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [activePoint, setActivePoint] = useState(0);

    /**
     * Get the active color by selecting the color in the active point if it exists.
     */
    const activeColor = useMemo(() => {
        if (color.points) {
            if (color.points[activePoint] && color.points[activePoint].color) {
                return color.points[activePoint].color;
            } else {
                setActivePoint(0);
                return color.points[0].color;
            }
        }
        return color;
    }, [color, activePoint]);

    const [hexValue, setHexValue] = useState(activeColor.hex);
    const [rgbValue, setRgbValue] = useState(activeColor.rgb);

    /**
     * Set the hex and rgb values when the active color changes or the color picker modal is shown.
     */
    useEffect(() => {
        setHexValue(activeColor.hex);
        setRgbValue(activeColor.rgb);
    }, [activeColor, showColorPickerModal]);

    /**
     * Toggle the color picker modal.
     */
    const handleShowColorPickerModal = (event) => {
        event && event.stopPropagation();
        setAnchorEl(event ? event.currentTarget : null);
        setShowColorPickerModal((prevState) => !prevState);
    };

    /**
     * Change the color type.
     * @param {string} - color type (solid | linear | radial | transparent)
     */
    const onChangeColorType = (newType) => {
        const newColor = cloneDeep(color);
        newColor.type = newType;
        onChange(newColor);
    };

    /**
     * Change the rotation of linear gradient.
     * @param {number} - Rotation value.
     */
    const onChangeRotation = (newRotation) => {
        const newColor = cloneDeep(color);
        newColor.rotation = newRotation;
        onChange(newColor);
    };

    /**
     * Add, update or delete the color points.
     * @param {array{objects}} - Color points.
     */
    const onChangePoints = (newPoints) => {
        const newColor = cloneDeep(color);
        newColor.points = newPoints;
        onChange(newColor);
    };

    /**
     * Change the color in the active points. Calculate the hex value with transparency.
     * @param {object} - From react-color.
     */
    const onChangeColor = ({ rgb }) => {
        const newColor = cloneDeep(color);
        const hex = convertRgbToHex(Object.values(rgb));

        if (newColor.points) {
            newColor.points[activePoint].color.rgb = rgb;
            newColor.points[activePoint].color.hex = hex;
        } else {
            newColor.rgb = rgb;
            newColor.hex = hex;
        }

        setHexValue(hex);
        setRgbValue(rgb);
        onChange(newColor);
    };

    /**
     * Change the color in the active points. Calculate the hex value with transparency.
     * @param {object} - From react-color.
     */
    const onChangePreset = (colorPickerObject) => {
        const newColorPickerObject = cloneDeep(colorPickerObject);
        if (useGradients && !newColorPickerObject.points) {
            newColorPickerObject.points = [
                {
                    location: 0,
                    color: {
                        rgb: colorPickerObject.rgb,
                        hex: colorPickerObject.hex
                    }
                }
            ];
        }

        // If the color picker object has points and we are not using gradients, we need to remove the points and restructure the color object.
        if (!useGradients && newColorPickerObject.points) {
            newColorPickerObject.hex = newColorPickerObject.points[0].color.hex;
            newColorPickerObject.rgb = newColorPickerObject.points[0].color.rgb;
            delete newColorPickerObject.points;
        }

        onChange(newColorPickerObject);
        setShowColorPickerModal(false);

        if (useGradients) {
            setHexValue(newColorPickerObject.points[activePoint].color.hex);
            setRgbValue(newColorPickerObject.points[activePoint].color.rgb);
        } else {
            setHexValue(newColorPickerObject.hex);
            setRgbValue(newColorPickerObject.rgb);
        }
    };

    /**
     * Change the color on change input.
     * @param {string} - name of the input (hex | rgb).
     * @param {string} - value of the input (hex | rgb)
     */
    const onChangeInput = (name, value, resetAlpha = false) => {
        const newColor = cloneDeep(color);
        const { r, g, b } = activeColor.rgb;
        let { a } = activeColor.rgb;
        const type = name.split(' ')[0];
        let hex = activeColor.hex;
        let rgb = { r, g, b, a };

        switch (type) {
            case 'hex':
                value = value.charAt(0) !== '#' ? '#' + value : value;
                setHexValue(value);
                if (isHexColor(value)) {
                    if (resetAlpha) {
                        a = 1;
                    }
                    // If there is transparency, calculate the hex value with transparency.
                    if (a !== 1) {
                        hex = convertHexToHexAlpha(value, a) || '#000000';
                    } else {
                        hex = value;
                    }
                    const newRgb = convertHexToRgb(hex) || { r: 0, g: 0, b: 0, a: 1 };
                    rgb = {
                        r: newRgb.r,
                        g: newRgb.g,
                        b: newRgb.b,
                        a
                    };

                    if (newColor.points) {
                        newColor.points[activePoint].color.rgb = rgb;
                        newColor.points[activePoint].color.hex = hex;
                    } else {
                        newColor.rgb = rgb;
                        newColor.hex = hex;
                    }

                    onChange(newColor);
                }
                break;
            case 'rgb':
                const channel = name.split(' ')[1];
                value = (() => {
                    if (['r', 'g', 'b'].includes(channel)) {
                        if (value > 255) return 255;
                        if (value < 0) return 0;
                    }

                    if (['a'].includes(channel)) {
                        if (value > 1) return 1;
                        if (value < 0) return 0;
                    }

                    return value;
                })();

                rgb[channel] = Number(value);
                hex = convertRgbToHex(Object.values(rgb)) || '#000000';
                setRgbValue(rgb);

                if (newColor.points) {
                    newColor.points[activePoint].color.rgb = rgb;
                    newColor.points[activePoint].color.hex = hex;
                } else {
                    newColor.rgb = rgb;
                    newColor.hex = hex;
                }

                onChange(newColor);
                break;
            default:
                break;
        }
    };

    return {
        activeColor,
        hexValue,
        rgbValue,
        showColorPickerModal,
        anchorEl,
        handleShowColorPickerModal,
        onChangeColorType,
        onChangeRotation,
        onChangePoints,
        onChangeColor,
        onChangePreset,
        onChangeInput,
        activePoint,
        onChangeActivePoint: setActivePoint
    };
};

export default useColorPicker;
