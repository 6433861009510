import React, { PropsWithChildren } from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';

/**
 * The props for the CustomAccordion component.
 * The children props is removed from the AccordionProps because it does not allow null values event though the AccordionDetails does.
 */
interface Props extends Omit<AccordionProps, 'children'> {
    accordionSummaryProps?: AccordionSummaryProps;
    accordionDetailsProps?: AccordionDetailsProps;
    accordionSummaryTitle: string;
    accordionSummaryTitleClassName?: string;
}

/**
 * A custom Accordion component that wraps the Material UI Accordion component.
 * @param {CustomAccordionDetailsProps} accordionSummaryProps - The props for the AccordionSummary component.
 * @param {AccordionDetailsProps} accordionDetailsProps - The props for the AccordionDetails component.
 */
const Accordion: React.FC<PropsWithChildren<Props>> = (props) => {
    const { accordionSummaryTitle, children, accordionSummaryProps, accordionDetailsProps, accordionSummaryTitleClassName } = props;

    // Omit 'accordionSummaryTitle' from props before spreading onto MuiAccordion
    const { accordionSummaryTitle: _, accordionSummaryProps: __, accordionDetailsProps: ___, accordionSummaryTitleClassName: ____, ...accordionProps } = props;

    return (
        <MuiAccordion {...accordionProps}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} {...accordionSummaryProps}>
                <div className={classNames(accordionSummaryTitleClassName)}>{accordionSummaryTitle}</div>
            </AccordionSummary>
            <AccordionDetails {...accordionDetailsProps}>{children}</AccordionDetails>
        </MuiAccordion>
    );
};

export default Accordion;
