import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import './styles/main.scss';

export interface PreviewListItemType {
    image?: string;
    title?: string;
    icon?: string;
    description?: string;
    hasMuiIcon?: boolean;
}

interface Props extends PreviewListItemType {
    onSelectItem: () => void;
}

const PreviewListItem = ({ image, title, icon, description, hasMuiIcon = false, onSelectItem }: Props): JSX.Element => {
    return (
        <div className="preview-list-item" onClick={() => onSelectItem()}>
            <div className="preview-list-item__image-container">
                {image && <img className="preview-list-item__image" src={image} alt={title} />}
                {icon && !hasMuiIcon && !image && <img className="preview-list-item__icon" src={icon} alt={title} />}
                {icon && hasMuiIcon && !image && (
                    <div className="preview-list-item__icon">
                        <span className="preview-list-item__icon__mui">
                            <Icon>{icon}</Icon>
                        </span>
                    </div>
                )}
                {!icon && !image && <Icon className="preview-list-item__placeholder">bolt</Icon>}
            </div>
            <div className="preview-list-item__footer">
                {title && <div className="preview-list-item__footer__title">{title}</div>}
                {description && <div className="preview-list-item__footer__description">{description}</div>}
            </div>
        </div>
    );
};

export default PreviewListItem;
