import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CustomPicker } from 'react-color';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { convertHexToRgb, convertRgbToHex, getRgbValueFromString } from 'helpers/colors';
import colorOptions from 'components/template-designer/config/colorOptions';
import Setup from 'components/data/Setup';
import { NumberInputField } from 'components/template-designer/components/ui-components/number-input-field';
import { StylingHelpers } from 'components/template-designer/helpers/styling.helpers';
import { TextInputField } from 'components/template-designer/components/ui-components/text-input-field';
import '../styles/color-picker-modal.scss';

const ColorPickerModal = ({
    hexValue,
    rgbValue,
    onChangeColorType,
    onChangeRotation,
    onChangePoints,
    onChange,
    onChangePreset,
    onChangeInput,
    activePoint,
    onChangeActivePoint,
    colorType = colorOptions.solid,
    colorPoints,
    presetColors,
    getPresetColors,
    rotation,
    useGradients,
    usePipette,
    useAlpha,
    ...props
}) => {
    const [activeDrag, setActiveDrag] = useState(false);
    const [startPos, setStartPos] = useState();
    const [canDelete, setCanDelete] = useState(false);
    const pointsBar = useRef();

    // Is true if the EyeDropper API is available and the user has enabled the use of the pipette.
    const canUseEyeDropper = typeof EyeDropper === 'function' && usePipette;

    /**
     * Gets the filtered preset colors.
     * If gradients are enabled, return all colors except transparent.
     * If gradients are disabled, return only solid colors.
     */
    const filteredPresetColors = useMemo(() => {
        if (!presetColors && !getPresetColors) return [];

        const newPresetColors = presetColors || getPresetColors();
        if (useGradients) {
            return newPresetColors.filter((presetColor) => presetColor.color !== 'transparent');
        }
        return newPresetColors.filter((presetColor) => presetColor.color !== 'transparent' && !presetColor.color.includes('gradient'));
    }, [presetColors, getPresetColors, useGradients]);

    /**
     * Create mouse and keydown listener for handling the points bar.
     * Add, move, delete color point.
     */
    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousedown', handleCanDelete);
        document.addEventListener('keydown', handleDelete);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousedown', handleCanDelete);
            document.removeEventListener('keydown', handleDelete);
        };
    }, [colorPoints, canDelete, activeDrag, startPos]);

    /**
     * Handles the background type change.
     */
    const changeBackgroundType = useCallback(
        (e) => {
            // Set active point to 0.
            onChangeActivePoint(0);
            // Pass the new type.
            onChangeColorType(e?.target?.name ? e?.target?.name : e);
        },
        [onChangeActivePoint, onChangeColorType]
    );

    /**
     * Change state when the mouse is down on a color point.
     */
    const handleMouseDown = useCallback(
        (e, i) => {
            e.stopPropagation();

            // Set current active drag.
            setActiveDrag(i);

            // Set the correct active point.
            onChangeActivePoint(i);

            // Set the start position.
            setStartPos({ mouse: e.pageX, point: colorPoints[i].location });

            // Set the gradient position, so it can be deleted.
            setCanDelete(true);
        },
        [colorPoints, setActiveDrag, onChangeActivePoint, setStartPos, setCanDelete]
    );

    /**
     * Calculates the new color point position based on mouse movement.
     * Save the new color points.
     */
    const handleMouseMove = useCallback(
        (e) => {
            if (activeDrag === false) return;

            const { offsetWidth } = pointsBar.current;
            const change = (e.pageX - startPos.mouse) / offsetWidth;

            const points = cloneDeep(colorPoints);
            if (startPos.point + change > 1) {
                points[activeDrag].location = 1;
            } else if (startPos.point + change < 0) {
                points[activeDrag].location = 0;
            } else {
                points[activeDrag].location = Math.round((startPos.point + change) * 100) / 100;
            }

            onChangePoints(points);
        },
        [activeDrag, pointsBar, colorPoints, onChangePoints]
    );

    /**
     * Disables the active drag when mouse is released.
     */
    const handleMouseUp = useCallback(() => {
        setActiveDrag(false);
    }, [setActiveDrag]);

    /**
     * Checkes if the color points can be deleted by checking the className.
     */
    const handleCanDelete = useCallback(
        (e) => {
            if (e.target.classList.contains('color-picker-modal__gradient-container__bar__point')) {
                return setCanDelete(true);
            }

            setCanDelete(false);
        },
        [setCanDelete]
    );

    /**
     * Deletes a color point.
     */
    const handleDelete = useCallback(
        (e) => {
            // Can the color point be deleted.
            if (!canDelete) return;

            // Is the right key pressed.
            if (e.code !== 'Delete' && e.code !== 'Backspace') return;

            e.preventDefault();
            e.stopPropagation();

            // Are there enough color points.
            if (colorPoints.length <= 2) {
                return SnackbarUtils.warning(Translation.get('general.errors.colorPoints', 'template-designer'));
            }

            const points = cloneDeep(colorPoints);

            // Delete the color point.
            points.splice(activePoint, 1);

            onChangeActivePoint(0);

            // Saves the color points.
            onChangePoints(points);
            setCanDelete(false);
        },
        [colorPoints, activePoint, setCanDelete, onChangePoints, canDelete]
    );

    /**
     * Add a color point when clicking on the color points bar.
     */
    const addColorPoint = useCallback(
        (e) => {
            // Checks if the color points bar is clicked.
            if (e.target.className !== 'color-picker-modal__gradient-container__bar') return;

            // Calculates the pointer location.
            const location = (e.pageX - pointsBar.current.getBoundingClientRect().left) / pointsBar.current.offsetWidth;
            const points = cloneDeep(colorPoints);
            points.push({ location, color: cloneDeep(points[activePoint].color) });

            // Sorts the color points.
            points.sort((a, b) => a.location - b.location);

            // Calculates the new active point.
            const newActivePoint = points.findIndex((p) => p.location === location);
            onChangeActivePoint(newActivePoint);

            onChangePoints(points);
            setCanDelete(true);
        },
        [pointsBar, colorPoints, activePoint, onChangeActivePoint, onChangePoints, setCanDelete]
    );

    const handlePresetColors = (clr) => {
        if (useGradients) {
            if (clr.color.includes('radial')) {
                changeBackgroundType('radial');
            } else if (clr.color.includes('linear')) {
                changeBackgroundType('linear');
            } else if (clr.color.includes('transparent')) {
                changeBackgroundType('transparent');
            } else {
                changeBackgroundType('solid');
            }
        } else {
            if (clr.color.includes('transparent')) {
                changeBackgroundType('transparent');
            } else {
                changeBackgroundType('solid');
            }
        }

        if (!clr.gradientColor) {
            if (clr.color.startsWith('rgb')) {
                const rgb = getRgbValueFromString(clr.color);
                const hex = convertRgbToHex(rgb);

                onChangePreset({ hex, rgb, type: 'solid' });
            } else {
                onChangePreset({ hex: clr.color, rgb: convertHexToRgb(clr.color), type: 'solid' });
            }
        }

        if (clr.gradientColor) {
            onChangePreset(clr.gradientColor);
        }
    };

    /**
     * Activated the EyeDropper API of the browser and change the current color.
     */
    const onEyeDropper = () => {
        if (!canUseEyeDropper) return;

        // eslint-disable-next-line no-undef
        const eyeDropper = new EyeDropper();
        // Enter eyedropper mode
        eyeDropper.open().then((colorSelectionResult) => {
            // returns hex color value (#RRGGBB) of the selected pixel

            onChangeInput('hex', colorSelectionResult.sRGBHex, true);
        });
    };

    /**
     * Checks if the color points bar can be shown by checking the right color types.
     */
    const showColorPointsBar = useMemo(() => [colorOptions.linear, colorOptions.radial].includes(colorType), [colorType]);

    /**
     * Categorise colors into categories.
     */
    const generalColors = useMemo(() => filteredPresetColors && filteredPresetColors.filter((color) => color.brand === 'general'), [presetColors]);
    const brandColors = useMemo(
        () => filteredPresetColors && filteredPresetColors.filter((color) => color.brand !== 'general' && color.brand !== 'template'),
        [presetColors]
    );
    const templateColors = useMemo(() => filteredPresetColors && filteredPresetColors.filter((color) => color.brand === 'template'), [presetColors]);

    return (
        <div className="color-picker-modal">
            <div
                className={classNames('color-picker-modal__type-selection', {
                    'color-picker-modal__type-selection--transparent-selected': colorType === colorOptions.transparent
                })}>
                <Tooltip title={Translation.get('colorPicker.types.solid', 'ui-components')} placement="top">
                    <button
                        className={classNames(
                            'color-picker-modal__type-selection__button',
                            `color-picker-modal__type-selection__button--${colorOptions.solid}`,
                            {
                                ['color-picker-modal__type-selection__button--active']: colorType === colorOptions.solid
                            }
                        )}
                        name={colorOptions.solid}
                        onClick={changeBackgroundType}
                    />
                </Tooltip>
                {useGradients && (
                    <React.Fragment>
                        <Tooltip title={Translation.get('colorPicker.types.gradient', 'ui-components')} placement="top">
                            <button
                                className={classNames(
                                    'color-picker-modal__type-selection__button',
                                    `color-picker-modal__type-selection__button--${colorOptions.linear}`,
                                    {
                                        ['color-picker-modal__type-selection__button--active']: colorType === colorOptions.linear
                                    }
                                )}
                                name={colorOptions.linear}
                                onClick={changeBackgroundType}
                            />
                        </Tooltip>
                        <Tooltip title={Translation.get('colorPicker.types.radialGradient', 'ui-components')} placement="top">
                            <button
                                className={classNames(
                                    'color-picker-modal__type-selection__button',
                                    `color-picker-modal__type-selection__button--${colorOptions.radial}`,
                                    {
                                        ['color-picker-modal__type-selection__button--active']: colorType === colorOptions.radial
                                    }
                                )}
                                name={colorOptions.radial}
                                onClick={changeBackgroundType}
                            />
                        </Tooltip>
                    </React.Fragment>
                )}
                {useAlpha && (
                    <Tooltip title={Translation.get('colorPicker.types.transparent', 'ui-components')} placement="top">
                        <button
                            className={classNames(
                                'color-picker-modal__type-selection__button',
                                `color-picker-modal__type-selection__button--${colorOptions.transparent}`,
                                {
                                    ['color-picker-modal__type-selection__button--active']: colorType === colorOptions.transparent
                                }
                            )}
                            name={colorOptions.transparent}
                            onClick={changeBackgroundType}
                        />
                    </Tooltip>
                )}
            </div>

            {showColorPointsBar && (
                <div className="color-picker-modal__gradient-container">
                    <div
                        ref={pointsBar}
                        style={StylingHelpers.getLayerStyle({
                            background: { type: colorOptions.linear, rotation: 90, points: colorPoints }
                        })}
                        className="color-picker-modal__gradient-container__bar"
                        onClick={addColorPoint}>
                        {colorPoints.map((point, i) => (
                            <div
                                key={point.location}
                                onMouseDown={(e) => handleMouseDown(e, i)}
                                className={classNames('color-picker-modal__gradient-container__bar__point', activePoint === i && 'active')}
                                style={{ left: point.location * 100 + '%' }}
                            />
                        ))}
                    </div>

                    {colorType === colorOptions.linear && (
                        <NumberInputField
                            className="color-picker-modal__gradient-container__degrees"
                            size="small"
                            min={0}
                            max={360}
                            value={rotation}
                            onChange={(value) => onChangeRotation(value)}
                            InputProps={{
                                endAdornment: <div>deg</div>
                            }}
                        />
                    )}
                </div>
            )}

            {colorType !== colorOptions.transparent && (
                <React.Fragment>
                    <div className="color-picker-modal__picker">
                        <div className="color-picker-modal__picker__saturation">
                            <Saturation {...props} onChange={onChange} />
                        </div>
                        <div className="color-picker-modal__picker__pipette-sliders">
                            {canUseEyeDropper && (
                                <IconButton className="color-picker-modal__picker__pipette" size="small" onClick={onEyeDropper}>
                                    <Icon>colorize</Icon>
                                </IconButton>
                            )}
                            <div className="color-picker-modal__picker__pipette-sliders__sliders">
                                <div className="color-picker-modal__picker__hue">
                                    <Hue {...props} onChange={onChange} />
                                </div>
                                {useAlpha && (
                                    <div className="color-picker-modal__picker__alpha">
                                        <Alpha {...props} onChange={onChange} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="color-picker-modal__picker__input-fields">
                            <TextInputField
                                className="color-picker-modal__picker__input-fields__hex"
                                value={(() => {
                                    const value = hexValue;

                                    if (value.length > 7) {
                                        return value.slice(0, 7);
                                    }

                                    return value;
                                })()}
                                min={6}
                                max={8}
                                onChange={(value) => onChangeInput('hex', value)}
                            />

                            <div className="color-picker-modal__picker__input-fields__rgb">
                                <NumberInputField
                                    className="color-picker-modal__picker__input-fields__rgb-input"
                                    value={rgbValue.r}
                                    min={0}
                                    max={255}
                                    onChange={(value) => onChangeInput('rgb r', value)}
                                />
                                <NumberInputField
                                    className="color-picker-modal__picker__input-fields__rgb-input"
                                    value={rgbValue.g}
                                    min={0}
                                    max={255}
                                    onChange={(value) => onChangeInput('rgb g', value)}
                                />
                                <NumberInputField
                                    className="color-picker-modal__picker__input-fields__rgb-input"
                                    value={rgbValue.b}
                                    min={0}
                                    max={255}
                                    onChange={(value) => onChangeInput('rgb b', value)}
                                />
                                {useAlpha && (
                                    <NumberInputField
                                        className="color-picker-modal__picker__input-fields__rgb-input"
                                        value={(rgbValue.a * 100).toFixed()}
                                        min={0}
                                        max={100}
                                        onChange={(value) => {
                                            const newValue = value / 100;
                                            onChangeInput('rgb a', newValue);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {generalColors && generalColors.length > 0 && (
                        <div className="color-picker-modal__predefined-colors">
                            <p>{Translation.get('colorPicker.generalColors', 'ui-components')}</p>
                            <div className="color-picker-modal__predefined-colors__color-list">
                                {generalColors.map((clr) => (
                                    <Tooltip key={clr.color} title={clr.title} placement="top">
                                        <button
                                            className="color-picker-modal__predefined-colors__btn"
                                            style={{ background: clr.color }}
                                            onClick={() => handlePresetColors(clr)}
                                        />
                                    </Tooltip>
                                ))}
                            </div>
                        </div>
                    )}

                    {brandColors && brandColors.length > 0 && (
                        <div className="color-picker-modal__predefined-colors">
                            <p>
                                {Translation.get('colorPicker.brandColors', 'ui-components', {
                                    brand: Setup.get('brands')[brandColors[0].brand] || brandColors[0].brand
                                })}
                            </p>
                            <div className="color-picker-modal__predefined-colors__color-list">
                                {brandColors.map((clr) => (
                                    <Tooltip key={clr.color} title={clr.title} placement="top">
                                        <button
                                            className="color-picker-modal__predefined-colors__btn"
                                            style={{ background: clr.color }}
                                            onClick={() => handlePresetColors(clr)}
                                        />
                                    </Tooltip>
                                ))}
                            </div>
                        </div>
                    )}

                    {templateColors && templateColors.length > 0 && (
                        <div className="color-picker-modal__predefined-colors">
                            <p>{templateColors[0].brandLabel}</p>
                            <div className="color-picker-modal__predefined-colors__color-list">
                                {templateColors.map((clr) => (
                                    <Tooltip key={clr.color} title={clr.title} placement="top">
                                        <button
                                            className="color-picker-modal__predefined-colors__btn"
                                            style={{ background: clr.color }}
                                            onClick={() => handlePresetColors(clr)}
                                        />
                                    </Tooltip>
                                ))}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default CustomPicker(ColorPickerModal);
