import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import debounce from 'lodash/debounce';
import { Stack } from '@mui/material';
import Chip from 'components/ui-components-v2/Chip';
import Translation from 'components/data/Translation';
import SearchFieldInput from './input';
import SearchFieldFilter from './filter';

import '../styles/main.scss';

/** The search input key */
export type SearchFieldInputKey = 'searchTerm';

/** Default debounce delay time. */
const DEFAULT_DELAY_TIME = 300;

/** Default search term key. */
const SEARCH_TERM_KEY: SearchFieldInputKey = 'searchTerm';

/** This component has it's own custom name for these attributes, so it's needed remove the default attribute names. */
export type CustomOutlinedInputProps = Omit<OutlinedInputProps, 'onChange' | 'placeholder' | 'value' | 'className'>;
interface Props extends CustomOutlinedInputProps {
    /** ClassName for the most outer div. */
    className?: string;
    /** The current search term. */
    searchTerm?: string;
    /** The placeholder for the search field. */
    searchPlaceholder?: string;
    /** If true, the search will have a delay of 300ms before triggering the search. */
    wait?: boolean;
    /** If set, the search term will be forced to this value. */
    forceSearchTerm?: string;
    /** If true, the input element is focused during the first mount. */
    autoFocus?: boolean;
    /** Callback function that will be triggered when the search term changes. */
    onChange: (key: SearchFieldInputKey, searchTerm: string, isSearchCanceled?: boolean, filters?: string[]) => void;
    /** The helper text that will be displayed below the input field. */
    formHelperText?: React.ReactNode;
    /** The filters that will be displayed in the filter menu. */
    filters?: string[];
}

/**
 * This is the generic search field component. It's responsible for handling the search input.
 */
const SearchField: React.FC<Props> = ({
    searchTerm = '',
    searchPlaceholder = Translation.get('labels.search', 'common'),
    className,
    wait = true,
    forceSearchTerm = '',
    autoFocus = false,
    onChange,
    formHelperText,
    filters,
    onKeyDown
}) => {
    const [query, setQuery] = useState<string>(searchTerm);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    /**
     * Debounces the onChange event handler.
     * @param query - The search query.
     */
    const debouncedOnChange = useCallback(
        debounce((query: string, selectedFilters: string[]) => onChange(SEARCH_TERM_KEY, query, false, selectedFilters), DEFAULT_DELAY_TIME),
        []
    );

    /** Handles the cancel search event. */
    const handleCancelSearch = () => {
        setQuery('');
        onChange(SEARCH_TERM_KEY, '', true, selectedFilters);
    };

    /**
     * Handles the change event of the input field.
     * @param query - The new value of the input.
     */
    const handleOnChange = (query: string) => {
        setQuery(query); // Update the local query state for the input field.
        if (wait) {
            debouncedOnChange(query, selectedFilters); // If wait is true, debounce the change event.
            return;
        }
        onChange(SEARCH_TERM_KEY, query, false, selectedFilters); // If wait is false, trigger the change event immediately.
    };

    useEffect(() => {
        // Cleanup function to cancel the debounce on component unmount
        return () => debouncedOnChange.cancel();
    }, []);

    // Clear searchfield in case of an external reset.
    useEffect(() => {
        if (!searchTerm || searchTerm === '') {
            setQuery('');
        }
    }, [searchTerm]);

    useEffect(() => {
        if (forceSearchTerm && forceSearchTerm.length) {
            setQuery(forceSearchTerm);
        }
    }, [forceSearchTerm]);

    const handleChangeSelectedFilters = (filter: string) => {
        let newSelectedFilters: string[] = [];

        if (selectedFilters.includes(filter)) {
            newSelectedFilters = selectedFilters.filter((value) => value !== filter);
        } else {
            newSelectedFilters = [...selectedFilters, filter];
        }

        setSelectedFilters(newSelectedFilters);
        onChange(SEARCH_TERM_KEY, query, false, newSelectedFilters);
    };

    return (
        <div className={classNames('search-field', className)}>
            <div className="search-field__input">
                <SearchFieldInput
                    autoFocus={autoFocus}
                    query={query}
                    searchPlaceholder={searchPlaceholder}
                    formHelperText={formHelperText}
                    onHandleOnChange={handleOnChange}
                    onHandleCancelSearch={handleCancelSearch}
                    onKeyDown={onKeyDown}
                />
                {filters && (
                    <div className="search-field__filter">
                        <SearchFieldFilter filters={filters} selectedFilters={selectedFilters} onChangeSelectedFilters={handleChangeSelectedFilters} />
                    </div>
                )}
            </div>
            {selectedFilters?.length > 0 && (
                <div className="search-field__chips">
                    <Stack direction="row" spacing={1}>
                        {selectedFilters.map((filter) => (
                            <Chip key={filter} size="small" label={filter} onDelete={() => handleChangeSelectedFilters(filter)} />
                        ))}
                    </Stack>
                </div>
            )}
        </div>
    );
};

export default SearchField;
