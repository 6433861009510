import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Translation from 'components/data/Translation';
import AssetHelpers from 'components/data/AssetHelpers';
import Illustration from 'components/ui-components-cape/Illustration';
import CollectionViewCount from './collection-view-count';

import './../styles/main.scss';

class CollectionView extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string })),
        hideInfo: PropTypes.bool,
        count: PropTypes.number,
        categories: PropTypes.array,
        acceptedTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool])
    };

    static defaultProps = {
        hideInfo: false
    };

    constructor(props) {
        super(props);

        const acceptedTypes = AssetHelpers.getAcceptedFileTypes(props.acceptedTypes);

        this.state = {
            showOverlay: false,
            acceptedTypes
        };
    }

    showOverlay = () => {
        this.setState({ showOverlay: true });
    };

    hideOverlay = () => {
        this.setState({ showOverlay: false });
    };

    render() {
        const { items, title, onClick, hideInfo, categories, thumbnail, subCollectionCount, small } = this.props;
        let { count } = this.props;
        const { showOverlay, acceptedTypes } = this.state;

        const creativesCount = items.length;
        count = count ? count : creativesCount;
        const classCount = count >= 4 ? 'more' : `${count}`;

        return (
            <div
                className="collection-view"
                onClick={onClick}
                onMouseEnter={() => {
                    this.showOverlay();
                }}
                onMouseLeave={() => {
                    this.hideOverlay();
                }}>
                <div className="collection-view__grid-wrapper" style={{ height: this.props.hideInfo ? '100%' : 'calc(100% - 70px)' }}>
                    {!thumbnail && (
                        <div className={`collection-view__grid-container collection-view__grid-container--${classCount}`}>
                            {count === 0 && <Illustration />}
                            {count > 0 && (
                                <div
                                    className="First"
                                    style={{
                                        backgroundImage: `url(${items[0] && items[0].url ? items[0].url : ''})`
                                    }}></div>
                            )}
                            {count > 1 && (
                                <div
                                    className="Second"
                                    style={{
                                        backgroundImage: `url(${items[1] && items[1].url ? items[1].url : ''})`
                                    }}></div>
                            )}
                            {count > 2 && (
                                <div
                                    className="Third"
                                    style={{
                                        backgroundImage: `url(${items[2] && items[2].url ? items[2].url : ''})`
                                    }}></div>
                            )}
                            {count > 3 && (
                                <div className="Fourth" style={{ backgroundImage: `url(${items[3] && items[3].url ? items[3].url : ''})` }}>
                                    {count > 4 && showOverlay && (
                                        <React.Fragment>
                                            <div className="overlay">+{count - 4}</div>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {thumbnail && (
                        <div className={'collection-view__grid-container collection-view__grid-container--1'}>
                            <div className="First" style={{ backgroundImage: `url(${thumbnail})` }}></div>
                        </div>
                    )}

                    {!hideInfo && (
                        <div className="collection-view__info-wrapper">
                            <div className="collection-view__info-wrapper__title">{title}</div>
                            <CollectionViewCount acceptedTypes={acceptedTypes} categories={categories} count={count} subCollectionCount={subCollectionCount} />
                        </div>
                    )}
                </div>
                <div className={classNames('collection-view__badge', { 'collection-view__badge--small': small })}>
                    {Translation.get('snackbar.collection_one', 'content-space')}
                </div>
            </div>
        );
    }
}

export default CollectionView;
