import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/main.scss';

/**
 * Display an icon corresponding to a publish status
 * @param {*} param0
 * @returns icon component
 */
const Status = ({ status, className, big, small, white, primary, secondary }) => {
    switch (status) {
        case 'done':
            return (
                <div
                    className={classNames('status', 'status__done', className, {
                        'status--big': big,
                        'status--small': small,
                        'status--white': white,
                        'status--primary': primary,
                        'status--secondary': secondary
                    })}>
                    <Icon fontSize="inherit">done</Icon>
                </div>
            );
        case 'failed':
        case 'connectionFail':
            return (
                <div
                    className={classNames('status', 'status__failed', className, {
                        'status--big': big,
                        'status--small': small,
                        'status--white': white,
                        'status--primary': primary,
                        'status--secondary': secondary
                    })}>
                    <Icon fontSize="inherit">error_outline</Icon>
                </div>
            );
        case 'invalid':
            return (
                <div
                    className={classNames('status', 'status__invalid', className, {
                        'status--big': big,
                        'status--small': small,
                        'status--white': white,
                        'status--primary': primary,
                        'status--secondary': secondary
                    })}>
                    <Icon fontSize="inherit">block</Icon>
                </div>
            );
        case 'cancelled':
            return (
                <div
                    className={classNames('status', 'status__cancelled', className, {
                        'status--big': big,
                        'status--small': small,
                        'status--white': white,
                        'status--primary': primary,
                        'status--secondary': secondary
                    })}>
                    <Icon fontSize="inherit">close</Icon>
                </div>
            );
        case 'pending':
            return (
                <div
                    className={classNames('status', 'status__pending', className, {
                        'status--big': big,
                        'status--small': small,
                        'status--white': white,
                        'status--primary': primary,
                        'status--secondary': secondary
                    })}>
                    <Icon fontSize="inherit">pending</Icon>
                </div>
            );
        case 'queued':
            return (
                <div
                    className={classNames('status', 'status__queued', className, {
                        'status--big': big,
                        'status--small': small,
                        'status--white': white,
                        'status--primary': primary,
                        'status--secondary': secondary
                    })}>
                    <Icon fontSize="inherit">queue</Icon>
                </div>
            );
        case 'scheduled':
            return (
                <div
                    className={classNames('status', 'status__scheduled', className, {
                        'status--big': big,
                        'status--small': small,
                        'status--white': white,
                        'status--primary': primary,
                        'status--secondary': secondary
                    })}>
                    <Icon fontSize="inherit">schedule</Icon>
                </div>
            );
        case 'starting':
        case 'processing':
            return <CircularProgress className={classNames('status', 'status__processing', className)} size={big ? 48 : small ? 16 : 24} />;
        default:
            return null;
    }
};

Status.propTypes = {
    status: PropTypes.oneOf(['done', 'failed', 'connectionFail', 'invalid', 'cancelled', 'starting', 'processing', 'new', 'pending', 'queued', '']),
    big: PropTypes.bool,
    small: PropTypes.bool,
    white: PropTypes.bool
};

Status.defaultProps = {
    status: 'new'
};

export default Status;
