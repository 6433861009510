import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Iconbutton from '@mui/material/IconButton';
import { ButtonProps } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import AlertBoxType from '../interfaces/alert-box-type';
import '../styles/main.scss';

interface Action extends ButtonProps {
    label: string;
    variant?: 'outlined';
    size?: 'small';
}

interface Props {
    className?: string;
    type: AlertBoxType;
    onClose?: (alertId: string | null) => void;
    children: JSX.Element | string;
    hideClose?: boolean;
    standAlone?: boolean;
    alertId?: string | null;
    action?: Action;
    customAction?: JSX.Element;
}

const AlertBox = ({ alertId = null, type = 'info', children, onClose, hideClose, standAlone, className, action, customAction }: Props) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (open) return;
        onClose?.(alertId);
    }, [open]);

    const icons = {
        success: 'check_circle_outline',
        error: 'error_outline',
        warning: 'warning_amber',
        info: 'error_outline'
    };

    if (!open) return null;

    return (
        <div
            className={classNames('alert-box', `alert-box--${type}`, className, {
                'alert-box--stand-alone': standAlone
            })}>
            <div className="alert-box__content">
                <div className={classNames('alert-box__icon', `alert-box__icon--${type}`)}>
                    <Icon>{icons[type]}</Icon>
                </div>
                <div className="alert-box__copy">{children}</div>
            </div>
            <div className="alert-box__actions-container">
                {action && (
                    <Button
                        {...action}
                        size="small"
                        className={classNames(
                            'alert-box__actions-container__action',
                            {
                                'alert-box__actions-container__action--space-right': !hideClose
                            },
                            action.className
                        )}>
                        {action.label}
                    </Button>
                )}
                {customAction && customAction}
                {!hideClose && (
                    <div className="alert-box__actions-container__close">
                        <Iconbutton size="small" onClick={() => setOpen(false)}>
                            <Icon>close</Icon>
                        </Iconbutton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AlertBox;
