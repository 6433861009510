import React from 'react';
import moment from 'moment/moment.js';

type InputDate = string | Date | number | moment.Moment;

interface Props {
    date?: InputDate;
    type?:
        | 'simple'
        | 'simpleDayTime'
        | 'date'
        | 'dateTime'
        | 'dateTimeShort'
        | 'dateTimeExact'
        | 'dateTimeIso'
        | 'dayMonth'
        | 'monthName'
        | 'monthNameYear'
        | 'dayMonthYear'
        | 'minSec'
        | 'fromNow';
    className?: string;
}

// Format a date / time.
const getDateFormatted = (date?: InputDate, type?: string): string => {
    switch (type) {
        case 'simple':
            return moment.utc(date).local().format('lll');
        case 'simpleDayTime':
            return moment.utc(date).local().format('ll HH:mm');
        case 'date':
            return moment.utc(date).local().format('DD-MM-YYYY');
        case 'dateTime':
            return moment.utc(date).local().format('DD MMM YYYY HH:mm');
        case 'dateTimeShort':
            return moment.utc(date).local().format('DD-MM-YY HH:mm');
        case 'dateTimeExact':
            return moment.utc(date).local().format('DD-MM-YYYY HH:mm:ss');
        case 'dateTimeIso':
            return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
        case 'dayMonth':
            return moment.utc(date).local().format('D');
        case 'monthName':
            return moment.utc(date).local().format('MMMM');
        case 'monthNameYear':
            return moment.utc(date).local().format('MMMM YYYY');
        case 'dayMonthYear':
            return moment.utc(date).local().format('DD-MM-YYYY');
        case 'minSec':
            return moment.utc(date).local().format('mm:ss');
        case 'fromNow':
            return moment.utc(date).local().fromNow();
        default:
            return moment.utc(date).local().calendar();
    }
};

const DateFormat = ({ date, type, className }: Props) => {
    return <span className={className}>{getDateFormatted(date, type)}</span>;
};

export default DateFormat;

export { DateFormat, getDateFormatted };
