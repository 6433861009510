import React, { useState } from 'react';
import { FormControlLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Menu from 'components/ui-components-v2/Menu';
import Icon from 'components/ui-components-v2/Icon';
import Checkbox from 'components/ui-components-v2/Checkbox';

interface Props {
    filters: string[];
    selectedFilters: string[];
    onChangeSelectedFilters: (filter: string) => void;
}

const SearchFieldFilter: React.FC<Props> = ({ filters, selectedFilters, onChangeSelectedFilters }) => {
    const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null);
    /**
     * Open the popper for the filter.
     * @param event - The event from the input.
     */
    const openPopper = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFilterAnchor(event.currentTarget);
    };

    /**
     * Close the popper for the filter.
     */
    const closeMenu = () => {
        setFilterAnchor(null);
    };

    return (
        <>
            <IconButton onClick={openPopper}>
                <Icon>filter_list</Icon>
            </IconButton>
            <Menu anchorEl={filterAnchor} open={!!filterAnchor} onClose={closeMenu}>
                {filters.map((filter) => {
                    return (
                        <MenuItem key={filter} dense>
                            <FormControlLabel
                                key={filter}
                                control={<Checkbox size="small" checked={selectedFilters.includes(filter)} />}
                                label={filter}
                                onChange={() => onChangeSelectedFilters(filter)}
                            />
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default SearchFieldFilter;
