import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import './main.scss';

interface Props {
    /**
     * Custom className for styling.
     */
    className?: string;
    /**
     * Original width.
     */
    width: number;
    /**
     * Original height.
     */
    height: number;
    /**
     * Max size of the icon.
     */
    maxSize: number;
    /**
     * Border radius of the icon.
     */
    borderRadius?: {
        topLeft: number;
        topRight: number;
        bottomRight: number;
        bottomLeft: number;
    };
    /**
     * Rotation of the icon.
     */
    rotation?: number;
    /**
     * Only show the border.
     */
    onlyBorder?: boolean;
}

/**
 * @function PreviewIcon
 * @description A simple React component to render a preview icon. Like a preview of the format.
 */
const PreviewIcon = ({ className, width, height, maxSize, borderRadius, rotation, onlyBorder }: Props) => {
    /**
     * Calculate the thumbnail size based on the width and height.
     */
    const previewSize: CSSProperties = {
        width,
        height,
        transform: rotation !== undefined ? `rotate(${rotation}deg)` : undefined,
        borderRadius: (() => {
            if (!borderRadius) return 0;
            const { topLeft, topRight, bottomRight, bottomLeft } = borderRadius;
            return `${topLeft}px ${topRight}px ${bottomRight}px ${bottomLeft}px`;
        })()
    };

    const maxDimension = Math.max(width, height);
    if (maxDimension > maxSize) {
        const scalingFactor = maxSize / maxDimension;
        previewSize.width = width * scalingFactor;
        previewSize.height = height * scalingFactor;
    }

    return (
        <div
            style={previewSize}
            className={classNames(
                'preview-icon',
                {
                    ['preview-icon--only-border']: onlyBorder
                },
                className
            )}
        />
    );
};

export { PreviewIcon };
