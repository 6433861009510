import React from 'react';
import classNames from 'classnames';
import { TemplateType } from 'types/templates.type';
import TemplatePlaceholderIcon from '../TemplatePlaceholderIcon';
import './styles/main.scss';

interface Props {
    image?: string;
    title?: string;
    icon?: string;
    templateType?: TemplateType;
    description?: string;
    onSelectItem: () => void;
    className?: string;
}

/**
 * Preview card
 * props.image Image shown in card
 * props.title Title of the card
 * props.icon Icon being shown if no image is set
 * props.description description of the card
 * props.onClick Function being executed on click of item
 */
const PreviewCard = ({ image, title, icon, templateType, description, onSelectItem, className }: Props) => {
    return (
        <div className={classNames('preview-card', className)} onClick={() => onSelectItem()}>
            <div className="preview-card__image-container">
                {image && <img className="preview-card__image" src={image} alt={title} />}
                {icon && !image && <img className="preview-card__icon" src={icon} alt={title} />}
                {!icon && !image && templateType && <TemplatePlaceholderIcon templateType={templateType} size="large" />}
            </div>
            <div className="preview-card__footer">
                {title && <h4>{title}</h4>}
                {description && <p>{description}</p>}
            </div>
        </div>
    );
};

export default PreviewCard;
