import React from 'react';
import PreviewListItem, { PreviewListItemType } from '../PreviewListItem';

import './styles/main.scss';

interface Props<T extends PreviewListItemType> {
    items: T[];
    className?: string;
    onSelect: (item: T) => void;
}

/**
 * The component is responsible for rendering a list of preview list item.
 */
const PreviewListView = <T extends PreviewListItemType>({ items, className, onSelect }: Props<T>) => {
    return (
        <div className={`preview-list-view ${className}`}>
            {items.map((item, index) => {
                return <PreviewListItem key={index} onSelectItem={() => onSelect(item)} {...item} />;
            })}
        </div>
    );
};

export default PreviewListView;
