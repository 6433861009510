import React from 'react';
import { TemplateType } from 'types/templates.type';
import Columns from 'components/ui-base/Columns';
import PreviewCard from '../PreviewCard';

import './styles/main.scss';

const DEFAULT_GRID_COUNT = 3;

interface Classes {
    previewCard?: string;
}

interface PreviewGridView {
    title?: string;
    templateType?: TemplateType;
    thumbnail?: string;
    image?: string;
    icon?: string;
    description?: string;
}

interface Props<T extends PreviewGridView> {
    items: T[];
    gridCount?: number;
    classes?: Classes;
    onSelect: (item: T) => void;
}

/**
 * The component is responsible for rendering a grid of preview cards.
 */
const PreviewGridView = <T extends PreviewGridView>({ items, gridCount = DEFAULT_GRID_COUNT, classes, onSelect }: Props<T>) => {
    return (
        <Columns count={gridCount}>
            {items?.map((item, index) => (
                <PreviewCard
                    className={`preview-grid-view__card ${classes?.previewCard}`}
                    key={index}
                    image={item?.thumbnail}
                    title={item.title}
                    templateType={item.templateType}
                    description={item.description}
                    onSelectItem={() => onSelect(item)}
                />
            ))}
        </Columns>
    );
};

export default PreviewGridView;
