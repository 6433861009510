import React from 'react';

/**
 * Grid Sorter
 * This component takes a list of blocks, and aligns every block to match the most ideal setup with loosing as little space as possible.
 */
export default class GridBlockFill extends React.Component {
    // Start fitting the items
    static fitStart = (availableSpace, items) => {
        const results = [];
        GridBlockFill.fitItems(availableSpace, items, [], results);
        // Sort and give a lower score to more items in a row
        return results.sort((a, b) => (a.score > b.score ? 1 : -1))[0];
    };

    // Fit items in a space.
    // Takes the items and available space as parameters.
    // The used and results parameters are references.
    static fitItems = (availableSpace, items, used, results) => {
        let found = false;
        // Loop through items
        Object.keys(items).forEach((itemKey, k) => {
            const item = items[itemKey];

            // Check whether item fits the space, if so continue loop
            if (!used.includes(itemKey) && !item.used) {
                if (item.displayWidth <= availableSpace) {
                    GridBlockFill.fitItems(availableSpace - item.displayWidth, items, [...used, itemKey], results);
                    found = true;
                }
            }
        });
        // Not found, the chain ends. Add the result
        if (!found) {
            results.push({ used: used, availableSpace: availableSpace, score: availableSpace + 30 * used.length });
        }
    };

    // Fit items in grid
    static fitGrid = (startWidth, items) => {
        let y = 0;
        let total = Object.keys(items).length;
        let x = 0;
        let xEnd = startWidth;
        let loop = 0;

        while (total > 0) {
            loop++;

            // Get x width
            Object.keys(items).forEach((itemKey, k) => {
                if (items[itemKey].used) {
                    // Check whether we are in the current Y space
                    if (items[itemKey].y <= y && items[itemKey].y2 > y) {
                        // If a block starts at the current X, the X moves
                        if (items[itemKey].x == x) {
                            x = items[itemKey].displayWidth + x;
                            if (xEnd < x) {
                                xEnd = x;
                            }
                        }

                        if (items[itemKey].x < xEnd && items[itemKey].x > x) {
                            xEnd = items[itemKey].x;
                        }
                    }
                }
            });

            // Get items
            const itemsFound = GridBlockFill.fitStart(xEnd - x, items);

            // Check next open block in row
            if (itemsFound.used.length == 0 && xEnd != startWidth) {
                x = xEnd;
                xEnd = startWidth;
            }
            // End of row reached, move to next
            else if (itemsFound.used.length == 0 && xEnd == startWidth) {
                x = 0;
                xEnd = startWidth;

                let yNew = 100000;
                Object.keys(items).forEach((itemKey, k) => {
                    if (items[itemKey].used) {
                        // Check whether we are in the current Y space
                        if (items[itemKey].y2 > y && items[itemKey].y2 < yNew) {
                            yNew = items[itemKey].y2;
                        }
                    }
                });
                y = yNew;
            }
            // Items found, set data
            else {
                // Save position
                itemsFound.used.forEach((itemKey) => {
                    items[itemKey].used = 1;
                    items[itemKey].x = x;
                    items[itemKey].x2 = x + items[itemKey].displayWidth;
                    items[itemKey].y = y;
                    items[itemKey].y2 = y + items[itemKey].displayHeight;
                    x = x + items[itemKey].displayWidth;
                    total--;
                });
            }

            if (loop > 150) {
                return;
            }
        }
    };

    /**
     * Returns position for items in grid view
     * Width: pixels width available
     * itemsInput: format
     *      "A":{'width':970,'height':250},
     *      "B":{'width':728,'height':90}
     */

    static returnPositions(width, itemsInput, gutter, bottomSpace) {
        const items = { ...itemsInput };
        let height = 0;

        const count = Object.keys(itemsInput);

        // If there are less than 50 items, we can fit them all in one go
        if (count.length < 40) {
            Object.keys(items).forEach((itemKey, k) => {
                items[itemKey].displayWidth = items[itemKey].width + gutter;
                let space = bottomSpace;
                if (items[itemKey].width < 400) {
                    space = bottomSpace * 2;
                }
                items[itemKey].displayHeight = items[itemKey].height + gutter + space;
            });

            GridBlockFill.fitGrid(width, items);
            Object.keys(items).forEach((itemKey, k) => {
                if (items[itemKey].y2 > height) {
                    height = items[itemKey].y2;
                }
            });

            return { positions: items, height: height + 50 };
        }
        // Very big amount of items, we need to do this less consuming
        else {
            let y = 0;
            let yNext = 0;
            let x = 0;
            Object.keys(items).forEach((itemKey, k) => {
                items[itemKey].displayWidth = items[itemKey].width + gutter;

                // Get x and y
                if (x + items[itemKey].displayWidth > width) {
                    x = 0;
                    y = yNext;
                }

                // Set new positions
                items[itemKey].x = x;
                items[itemKey].x2 = x + items[itemKey].displayWidth;
                items[itemKey].y = y;
                items[itemKey].y2 = y + items[itemKey].displayHeight;

                items[itemKey].displayWidth = items[itemKey].width + gutter;
                let space = bottomSpace;
                if (items[itemKey].width < 400) {
                    space = bottomSpace * 2;
                }
                items[itemKey].displayHeight = items[itemKey].height + gutter + space;
                if (y + items[itemKey].displayHeight > yNext) {
                    yNext = y + items[itemKey].displayHeight;
                }

                x = x + items[itemKey].displayWidth;
            });
            height = yNext;

            return { positions: items, height: height + 50 };
        }
    }
}
