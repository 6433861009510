import React, { useMemo, useRef } from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classnames from 'classnames';
import colorOptions from 'components/template-designer/config/colorOptions';
import getCssColor from 'components/input/ColorSelector/utils/getCssColor';
import { TextInputField } from 'components/template-designer/components/ui-components/text-input-field';
import { NumberInputField } from 'components/template-designer/components/ui-components/number-input-field';
import Translation from 'components/data/Translation';
import Tooltip from 'components/ui-components-v2/Tooltip';
import useColorPicker from './hooks/useColorPicker';
import ColorPickerModal from './components/color-picker-modal';
import './styles/main.scss';

const ColorPicker = ({
    color,
    useGradients = true,
    onChange,
    presetColors,
    getPresetColors,
    disabled,
    usePipette = false,
    useAlpha = true,
    dataCyPrefix = ''
}) => {
    const {
        activeColor,
        hexValue,
        rgbValue,
        showColorPickerModal,
        anchorEl,
        handleShowColorPickerModal,
        onChangeColorType,
        onChangeRotation,
        onChangePoints,
        onChangeColor,
        onChangePreset,
        onChangeInput,
        activePoint,
        onChangeActivePoint
    } = useColorPicker(color, onChange, useGradients);

    const inputRef = useRef(null);

    /**
     * Gets the background color for the button.
     */
    const buttonBackground = useMemo(() => {
        if (color.type === colorOptions.transparent) {
            return {
                background: colorOptions.transparent
            };
        }

        if (color.type === colorOptions.solid) {
            if (activeColor.rgb && activeColor.rgb.a !== undefined) {
                const { r, g, b, a } = activeColor.rgb;
                return {
                    background: `linear-gradient(90deg, rgba(${r}, ${g}, ${b}, 1) 0%, rgba(${r}, ${g}, ${b}, 1) 49.9%, rgba(${r}, ${g}, ${b}, ${a}) 50.1%, rgba(${r}, ${g}, ${b}, ${a}) 100%)`
                };
            }

            return {
                background: activeColor.hex
            };
        }

        if ([colorOptions.linear, colorOptions.radial].includes(color.type)) {
            return {
                background: getCssColor(color)
            };
        }

        return {
            background: activeColor.hex
        };
    }, [color, activeColor]);

    return (
        <div className="color-picker">
            <TextInputField
                dataCyPrefix={dataCyPrefix}
                className="color-picker__color-input"
                size="small"
                onChange={(value) => onChangeInput('hex', value)}
                onBlur={(value) => {
                    value = value.charAt(0) !== '#' ? '#' + value : value;
                    value = value.padEnd(7, value.charAt(value.length - 1));
                    onChangeInput('hex', value);
                }}
                value={(() => {
                    if (color.type === colorOptions.transparent) return Translation.get('general.colorOptions.transparent', 'template-designer');

                    const value = hexValue.toUpperCase();

                    if (value.length > 7) {
                        return value.slice(0, 7);
                    }

                    return value;
                })()}
                inputRef={inputRef}
                disabled={disabled}
                name="hex"
                min={6}
                max={8}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        inputRef.current && inputRef.current.blur();
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <div className="color-picker__color-container">
                            <button
                                style={buttonBackground}
                                onClick={handleShowColorPickerModal}
                                className={classnames('color-picker__button', disabled && 'color-picker__button--disabled')}
                            />
                            <div className="color-picker__grid" />
                        </div>
                    )
                }}
            />

            {useAlpha && color.type !== colorOptions.transparent && (
                <Tooltip title={Translation.get('general.colorOptions.opacity', 'template-designer')}>
                    <div>
                        <NumberInputField
                            className="color-picker__opacity-input"
                            size="small"
                            onChange={(value) => {
                                const newValue = value / 100;
                                onChangeInput('rgb a', newValue);
                            }}
                            min={0}
                            max={100}
                            showNumericInputs={false}
                            value={parseInt(color.type === colorOptions.transparent ? 0 : (rgbValue.a * 100).toFixed())}
                            disabled={disabled}
                        />
                    </div>
                </Tooltip>
            )}

            {/* Show the modal with the color picker. */}
            <ClickAwayListener onClickAway={handleShowColorPickerModal} mouseEvent="onMouseDown">
                <Popper open={showColorPickerModal} anchorEl={anchorEl} placement="bottom" className="color-picker__popover">
                    {showColorPickerModal && (
                        <ColorPickerModal
                            color={activeColor}
                            hexValue={hexValue}
                            rgbValue={rgbValue}
                            onChangeColorType={onChangeColorType}
                            onChangeRotation={onChangeRotation}
                            onChangePoints={onChangePoints}
                            onChange={onChangeColor}
                            onChangePreset={onChangePreset}
                            onChangeInput={onChangeInput}
                            activePoint={activePoint}
                            onChangeActivePoint={onChangeActivePoint}
                            colorType={color.type}
                            colorPoints={color.points}
                            presetColors={presetColors}
                            getPresetColors={getPresetColors}
                            rotation={color.rotation}
                            useGradients={useGradients}
                            usePipette={usePipette}
                            useAlpha={useAlpha}
                        />
                    )}
                </Popper>
            </ClickAwayListener>
        </div>
    );
};

export default ColorPicker;
