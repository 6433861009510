import React from 'react';
import classNames from 'classnames';
import CircularProgress from 'components/ui-components-v2/CircularProgress';

import './../styles/main.scss';

interface Props {
    isLoading?: boolean;
    children?: React.ReactNode;
    classes?: {
        root?: string;
        loaderBox?: string;
        loader?: string;
    };
}

const Loader = ({ isLoading, children, classes }: Props) => {
    if (!isLoading) {
        return children;
    }

    return (
        <div className={classNames('loader', classes?.root)}>
            <div className={classNames('loader__element', classes?.loaderBox)}>
                <CircularProgress className={classes?.loader} />
            </div>
        </div>
    );
};

export default Loader;
