import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useComponentSize } from 'hooks/useComponentSize';
import ColumnHelpers from 'components/data/ColumnHelpers';

import './styles/main.scss';

interface Props {
    children: JSX.Element | JSX.Element[];
    className?: string;
    targetSize?: number;
    onGetColumnCount?: (columnCount: number) => void;
}

const GridWrapper = ({ children, targetSize, className, onGetColumnCount }: Props) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const { width: componentWidth } = useComponentSize(componentRef);

    const columnCount = ColumnHelpers.getColumnCount(componentWidth, targetSize);

    useEffect(() => {
        if (onGetColumnCount) onGetColumnCount(columnCount);
    }, [columnCount]);

    if (!columnCount) return null;

    return (
        <div
            ref={componentRef}
            className={classNames('grid-wrapper', className, {
                'grid-wrapper--one': columnCount === 1,
                'grid-wrapper--two': columnCount === 2,
                'grid-wrapper--three': columnCount === 3,
                'grid-wrapper--four': columnCount === 4,
                'grid-wrapper--five': columnCount === 5,
                'grid-wrapper--six': columnCount === 6,
                'grid-wrapper--seven': columnCount === 7,
                'grid-wrapper--eight': columnCount === 8
            })}>
            {columnCount > 0 && children}
        </div>
    );
};

export default GridWrapper;
