import React, { Component } from 'react';
import { Popper, IconButton } from '@mui/material';
import Badge from 'components/ui-components-v2/Badge';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';

import '../styles/main.scss';

class IconDropdown extends Component {
    constructor(props) {
        super(props);

        this.arrowRef = React.createRef();
        this.popperRef = React.createRef();
        this.iconRef = React.createRef();

        this.state = {
            showIconDropdown: false
        };
    }

    componentDidMount() {
        document.addEventListener('click', this.closeIconDropdown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeIconDropdown);
    }

    closeIconDropdown = (e) => {
        if (!this.state.showIconDropdown) {
            return;
        }
        e.preventDefault();
        if (this.iconRef.current !== e.target && ![...this.iconRef.current.children].includes(e.target)) {
            this.setState({
                showIconDropdown: false
            });
        }
    };

    toggleIconDropdown = (e) => {
        e.preventDefault();
        this.setState({
            showIconDropdown: !this.state.showIconDropdown
        });
    };

    render() {
        const { icon, anchor, badgeContent, preventOpen, tooltip } = this.props;
        const { showIconDropdown } = this.state;

        return (
            <div className={'icon-dropdown' + this.props.className}>
                <Tooltip title={tooltip} disableHoverListener={tooltip ? false : true}>
                    <div>
                        {!anchor && (
                            <IconButton onClick={this.toggleIconDropdown} ref={this.iconRef} className="icon-dropdown__icon-button" size="large">
                                {badgeContent ? (
                                    <Badge
                                        badgeContent={badgeContent}
                                        color="primary"
                                        className={'icon-dropdown__no-pointer'}
                                        classes={{
                                            badge: 'icon-dropdown__badge icon-dropdown__no-pointer'
                                        }}>
                                        <Icon className="icon-dropdown__no-pointer">{icon}</Icon>
                                    </Badge>
                                ) : (
                                    <Icon className="icon-dropdown__no-pointer">{icon}</Icon>
                                )}
                            </IconButton>
                        )}
                        {anchor && (
                            <div ref={this.iconRef} className="icon-dropdown__avatar-ref">
                                <div onClick={this.toggleIconDropdown} className="icon-dropdown__avatar-button">
                                    {React.cloneElement(anchor, {
                                        className: 'icon-dropdown__avatar icon-dropdown__no-pointer'
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </Tooltip>
                <Popper
                    open={showIconDropdown && !preventOpen}
                    onClick={(e) => {
                        this.closeIconDropdown(e);
                        e.stopPropagation();
                    }}
                    ref={this.popperRef}
                    anchorEl={this.iconRef.current}
                    onClose={() => this.setState({ showIconDropdown: false })}
                    className="icon-dropdown__popover"
                    placement="bottom-end"
                    disablePortal={false}
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: true,
                            options: {
                                altBoundary: true,
                                rootBoundary: 'document',
                                padding: 8
                            }
                        },
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                                altAxis: true,
                                altBoundary: true,
                                tether: true,
                                rootBoundary: 'document',
                                padding: 8
                            }
                        },
                        {
                            name: 'arrow',
                            enabled: true,
                            options: {
                                element: this.arrowRef.current
                            }
                        }
                    ]}>
                    <span className="icon-dropdown__popover__arrow" ref={this.arrowRef} />
                    {this.props.children}
                </Popper>
            </div>
        );
    }
}

export default IconDropdown;
