import React from 'react';
import Translation from 'components/data/Translation';

import '../styles/collection-view-count.scss';

const CollectionViewCount = ({ acceptedTypes, categories, count, subCollectionCount }) => {
    return (
        <div className="collection-view-count">
            {acceptedTypes &&
                categories &&
                categories
                    .filter((cat) => acceptedTypes.includes(cat._id))
                    .map((cat, index) => (
                        <span key={`cat-${cat._id}`}>
                            {index > 0 ? ', ' : ''}
                            {cat.count} {cat._id}
                            {cat.count === 1 ? '' : 's'}
                        </span>
                    ))}
            {!acceptedTypes && (
                <span>
                    {count} {Translation.get('asset', 'content-space', { count })}
                </span>
            )}
            {subCollectionCount > 0 && (
                <span>
                    {subCollectionCount} {Translation.get('snackbar.collection', 'content-space', { count: subCollectionCount })}
                </span>
            )}
        </div>
    );
};

export default CollectionViewCount;
