import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import { GenericSvgIcon } from 'components/ui-components/GenericIcon';
import GenericIcon from 'components/ui-components/GenericIcon/components';

import '../styles/sidebar-items.scss';

export interface SidebarItem {
    title: string;
    description?: string;
    icon: string;
    isCustomSvg?: boolean; // If true, the icon is custom svg icon used in combination with the GenericIcon component.
    type: string; // The unique type of the sidebar item
}

interface Props {
    items: SidebarItem[];
    defaultActiveSidebarItem?: string;
    dataCyPrefix?: string;
    onChangeActiveSidebarItem: (type: string) => void;
}

/**
 * This component shows a vertical list of sidebar items to filter the content of the Dialog. It's used in the
 * ContentWithSidebarWrapper component, but can also be imported separately.
 */
const DialogSidebarItems: React.FC<Props> = ({ items, defaultActiveSidebarItem, dataCyPrefix, onChangeActiveSidebarItem }) => {
    const [activeItem, setActiveItem] = useState<string>('');

    // Set the default active item
    useEffect(() => {
        if (defaultActiveSidebarItem) {
            changeActiveItem(defaultActiveSidebarItem);
        }
    }, [defaultActiveSidebarItem]);

    // Set the new active item and call the callback function
    const changeActiveItem = (type: string) => {
        setActiveItem(type);
        onChangeActiveSidebarItem(type);
    };

    return (
        <div className="dialog-sidebar-items">
            {items.map((item) => (
                <div
                    data-cy={`${dataCyPrefix}-${item.title}-div`}
                    key={item.type}
                    className={classNames('dialog-sidebar-items__item', {
                        'dialog-sidebar-items__item--active': activeItem === item.type
                    })}
                    onClick={() => changeActiveItem(item.type)}>
                    <div
                        className={classNames('dialog-sidebar-items__item__icon-wrapper', {
                            'dialog-sidebar-items__item__icon-wrapper--active': activeItem === item.type
                        })}>
                        {item.isCustomSvg ? (
                            <div className="dialog-sidebar-items__item__generic-icon">
                                <GenericIcon
                                    className={classNames('dialog-sidebar-items__item__icon', {
                                        'dialog-sidebar-items__item__icon--active': activeItem === item.type
                                    })}
                                    icon={item.icon as GenericSvgIcon}
                                />
                            </div>
                        ) : (
                            <Icon
                                className={classNames('dialog-sidebar-items__item__icon', {
                                    'dialog-sidebar-items__item__icon--active': activeItem === item.type
                                })}>
                                {item.icon}
                            </Icon>
                        )}
                    </div>

                    <div className="dialog-sidebar-items__item__copy">
                        <div className="dialog-sidebar-items__item__title">{item.title}</div>
                        <div
                            className={classNames('dialog-sidebar-items__item__description', {
                                'dialog-sidebar-items__item__description--active': activeItem === item.type
                            })}>
                            {item.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DialogSidebarItems;
